import { Injector, Component, ViewEncapsulation} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './default-brand.component.html',
    selector: 'default-brand',
    encapsulation: ViewEncapsulation.None,
})
export class DefaultBrandComponent extends AppComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }
}
